import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Home } from './Home';

// import { About } from "./about/About"
// import { Papers } from "./papers/Papers"
import * as serviceWorker from './serviceWorker';
import { Router, Link } from "@reach/router"


ReactDOM.render(
  <Router>
    <Home path="/" />
    {/* <About path="/about" />
    <Papers path="/papers" /> */}
  </Router>,
  document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
