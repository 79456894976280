import React, { Component, PropTypes } from 'react';

import {
    Button, H1, H2, H3, H4, H5, H6, Text, Tag, Card,
    Elevation, Popover, Position, Menu, MenuItem, Icon
} from "@blueprintjs/core";


function to_apa(e) {

    // console.log(e)
    let year = e.year
    let authors = new Array();

    e.authors.forEach((a) => {
        // console.log(a)
        let fn = a.first
        let ln = a.last
        authors.push(`${ln}, ${fn.charAt(0)}.`)
    })

    let auths = authors.join(", ")

    let title = e.title
    let journal = e.journal

    let vol = null;
    if ('volume' in e) {
        vol = e.volume
    }

    let pages = null;
    if ('pages' in e) {
        if (e.pages.length === 2) {
            pages = `${e.pages[0]}-${e.pages[1]}`
        } else {
            pages = `${e.pages[0]}`
        }
    }

    let result = <p>{auths} ({year}). {title}. <i>{journal} {vol ? vol : ""}</i>, {pages ? pages : ""}</p>;

    let result2 = <Popover content={make_menu(e)} position={Position.RIGHT_TOP}>{result}</Popover>
    return (result2);
}


function make_menu(entry) {
    return (
        <Menu >
            {entry.pdf ? <MenuItem className="papersMenuItem" icon="document" text="Download PDF" href={`papers/${entry.pdf}`} /> : null}
            {entry.url ? <MenuItem className="papersMenuItem" icon="globe" text="View Online" href={entry.url} /> : null}
            {entry.scholar ? <MenuItem className="papersMenuItem" icon="search-around" text="Google Scholar" href={entry.scholar} /> : null}
        </Menu>
    );
}

function hello(e) {

}

export { to_apa };

