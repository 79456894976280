import React, { Component, PropTypes } from 'react';
import './Home.css';
import { to_apa } from "./cite"
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Router, Link } from "@reach/router"
import { IoLogoTwitter, IoLogoGithub, IoLogoGoogle } from "react-icons/io";
import avatar from "./avatar.png"
import { bib } from "./bib"
import { Button, ButtonGroup, H1, H2, H3, H4, H5, H6, Text, Tag, Popover, Card, Elevation, Position } from "@blueprintjs/core";

class Home extends Component {

    constructor(props) {
        super(props);

        this.handleHomeClick = this.handleHomeClick.bind(this)
        this.handleAboutClick = this.handleAboutClick.bind(this)
        this.handlePapersClick = this.handlePapersClick.bind(this)
        this.handleResize = this.handleResize.bind(this)

        window.addEventListener('resize', this.handleResize)

        var isMobile = /Mobi|Android/i.test(navigator.userAgent) ? true : false

        this.state = {
            view: "home",
            papers: bib,
            bibMenuOpen: false,
            bibMenuSelected: null,
            height: window.innerHeight,
            width: window.innerWidth,
            isMobile: isMobile,
        }


    }

    async componentDidMount() {

    }

    handleResize() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    handleHomeClick() {
        this.setState({ view: "home" })
    }

    handleAboutClick() {
        this.setState({ view: "about" })
    }

    handlePapersClick() {
        this.setState({ view: "papers" })
    }

    home() {
        const btnStyle = { color: "#555555" };
        return (
            <Grid fluid>
                <Row center="xs" className="avatarRowHome">
                    <Col xs={12} md={12}>
                        {/* <img src={avatar} className="avatar" onClick={this.handleHomeClick}></img> */}
                    </Col>
                </Row>
                <Row center="xs">
                    <Col xs={6} md={3} >
                        <H2 className="myName" onClick={this.handleHomeClick}>Andrei Amatuni</H2>
                    </Col>
                </Row>
                <Row center="xs">
                    <Col xs={6} md={3} >
                        <ButtonGroup className="navBtn" minimal={true} vertical={true}>
                            <Button onClick={this.handleAboutClick} className="navButton" style={btnStyle}>About</Button>
                            <Button onClick={this.handlePapersClick} className="navButton" style={btnStyle}>Papers</Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <Row className="socialLinkRow" center="xs" mt={1}>
                    <Col xs={6} md={3} >
                        <a className="socialLink" href="https://github.com/andreiamatuni"><IoLogoGithub /></a>
                        <a className="socialLink" href="https://twitter.com/andreiamatuni"><IoLogoTwitter /></a>
                        <a className="socialLink" href="https://scholar.google.com/citations?user=OsAUBrgAAAAJ"><i className="ai ai-google-scholar ai-1x"></i></a>
                    </Col>
                </Row>
            </Grid >
        );
    }

    about() {
        return (
            <Grid fluid>
                <Row center="xs" center="md" className="avatarRowHome">
                    <Col xs={12} md={12}>
                        {/* <img src={avatar} className="avatar" onClick={this.handleHomeClick}></img> */}
                    </Col>
                </Row>
                <Row center="xs">
                    <Col xs={6} md={3} >
                        <H2 className="myName" onClick={this.handleHomeClick}>Andrei Amatuni</H2>
                    </Col>
                </Row>
                <Row center="xs">
                    <Col xs={6} md={6} lg={6}>
                        <Text className="aboutText" onClick={this.handleAboutClick}>
                            I’m a PhD student in the Department of Psychology at The University of Texas at Austin. There I work with my advisor
                            Dr. Alison Preston researching early knowledge acquisition. I’m interested in understanding how knowledge is acquired through everyday experiences, looking specifically at the role that memory mechanisms play in shaping these early learned representations.</Text>
                    </Col>
                </Row>

                <Row center="xs" className="socialLinkRow">
                    <Col xs={6} md={3} >
                        <a className="socialLink" href="https://github.com/andreiamatuni"><IoLogoGithub /></a>
                        <a className="socialLink" href="https://twitter.com/andreiamatuni"><IoLogoTwitter /></a>
                        <a className="socialLink" href="https://scholar.google.com/citations?user=OsAUBrgAAAAJ"><i class="ai ai-google-scholar ai-1x"></i></a>
                    </Col>
                </Row>
            </Grid >
        );
    }

    papersDesktop(listItems) {
        return (
            <div>
                <Row center="xs" className="avatarRowPapers" top="ts" between="xs">

                    <Col xs={2} center="xs">
                        {/* <img src={avatar} className="avatarPapers" onClick={this.handleHomeClick}></img> */}
                        <H3 className="myNamePapers" onClick={this.handleHomeClick}>Andrei Amatuni</H3>
                        <a className="socialLink" href="https://github.com/andreiamatuni"><IoLogoGithub /></a>
                        <a className="socialLink" href="https://twitter.com/andreiamatuni"><IoLogoTwitter /></a>
                        <a className="socialLink" href="https://scholar.google.com/citations?user=OsAUBrgAAAAJ"><i class="ai ai-google-scholar ai-1x"></i></a>
                    </Col>

                    <Col xs={10} md={9}>
                        <ul className="bibList">
                            {listItems}
                        </ul>
                    </Col>

                    <Col xs={0} md={1}>
                    </Col>
                </Row>
            </div>
        );
    }

    papersMobile(listItems) {
        return (
            <div>
                <Row center="xs" className="avatarRowPapers">
                    <Col className="papersMobile" xs={12}>
                        {/* <img src={avatar} className="avatarPapers" onClick={this.handleHomeClick}></img> */}
                        <H2 className="myNamePapers" onClick={this.handleHomeClick}>Andrei Amatuni</H2>
                        <a className="socialLink" href="https://github.com/andreiamatuni"><IoLogoGithub /></a>
                        <a className="socialLink" href="https://twitter.com/andreiamatuni"><IoLogoTwitter /></a>
                        <a className="socialLink" href="https://scholar.google.com/citations?user=OsAUBrgAAAAJ"><i class="ai ai-google-scholar ai-1x"></i></a>
                        <ul className="bibList">
                            {listItems}
                        </ul>
                    </Col>
                </Row>
            </div >
        );
    }

    papers() {


        let entries = this.sortPapers();


        let listItems = entries.map(x => <li className="bibEntry">{to_apa(x)}</li>)
        // console.log(entries)
        // console.log(this.state.papers)

        let papersInner = null;
        if (!this.state.isMobile) {
            console.log("inside desktop")
            papersInner = this.papersDesktop(listItems)
        } else {
            console.log("inside mobile")
            papersInner = this.papersMobile(listItems)
        }

        // console.log(this.state.width)

        return (
            <Grid>
                {papersInner}
            </Grid >
        );
    }

    sortPapers(papers) {
        let entries = Object.values(this.state.papers);
        entries.sort((a, b) => {
            if (a.year < b.year) {
                return 1;
            }
            if (a.year > b.year) {
                return -1
            }

            if (a.authors[0].last > b.authors[0].last) {
                return 1;
            }
            if (a.authors[0].last < b.authors[0].last) {
                return -1
            }
        }
        )
        return entries;
    }

    render() {
        let inner = null;
        console.log(this.state)
        console.log(this.state.isMobile)
        if (this.state.view === "home") {
            inner = this.home()
        } else if (this.state.view === "about") {
            inner = this.about()
        } else if (this.state.view === "papers") {
            inner = this.papers()
        }

        return (
            <div>
                {inner}
            </div>
        );
    }
}

export { Home };
