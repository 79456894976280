var bib =
{
    "amatuni2021inthemoment": {
        "title": "In-the-Moment Visual Information from the Infant's Egocentric View Determines the Success of Infant Word Learning: A Computational Study",
        "authors": [
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Sara",
                "last": "Schroer"
            },
            {
                "first": "Yayun",
                "last": "Zhang"
            },
            {
                "first": "Ryan",
                "last": "Peters"
            },
            {
                "first": "Md. Alimoor",
                "last": "Reza"
            },
            {
                "first": "David",
                "last": "Crandall"
            },
            {
                "first": "Chen",
                "last": "Yu"
            }
        ],
        "year": 2021,
        "journal": "Proceedings of the 43rd Annual Conference of the Cognitive Science Society",
        "url": "https://psyarxiv.com/ky2bw/",
        "pubtype": "proceedings",
        "pdf": "amatuni2021.pdf",
        // "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=12378071831353153819"
    },
    "zhang2021multiplecue": {
        "title": "Human Learners Integrate Visual and Linguistic Information in Cross-Situational Verb Learning",
        "authors": [
            {
                "first": "Yayun",
                "last": "Zhang"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Ellis",
                "last": "Cain"
            },
            {
                "first": "Chen",
                "last": "Yu"
            }
        ],
        "year": 2021,
        "journal": "Proceedings of the 43rd Annual Conference of the Cognitive Science Society",

        "pubtype": "proceedings",
        "pdf": "multiple_cue_cogsci_2021.pdf",
        // "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=12378071831353153819"
    },
    "peters2021modelingjoint": {
        "title": "Modeling joint attention from egocentric vision",
        "authors": [
            {
                "first": "Ryan E.",
                "last": "Peters"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Sara E.",
                "last": "Schroer"
            },
            {
                "first": "Shujon",
                "last": "Naha"
            },
            {
                "first": "David",
                "last": "Crandall"
            },
            {
                "first": "Chen",
                "last": "Yu"
            }
        ],
        "year": 2021,
        "journal": "Proceedings of the 43rd Annual Conference of the Cognitive Science Society",

        "pubtype": "proceedings",
        "pdf": "peters2021.pdf",
        // "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=12378071831353153819"
    },
    "amatuni2020decoding": {
        "title": "Decoding Eye Movements in Cross-Situational Word Learning via Tensor Component Analysis",
        "authors": [
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Chen",
                "last": "Yu"
            }
        ],
        "year": 2020,
        "journal": "Proceedings of the 42nd Annual Conference of the Cognitive Science Society",
        "url": "https://psyarxiv.com/aep8f/",
        "pubtype": "proceedings",
        "pdf": "amatuni2020.pdf",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=12378071831353153819"
    },
    "zhang2020seeking": {
        "title": "Seeking Meaning: Examining a Cross-situational Solution to Learn Action Verbs Using Human Simulation Paradigm",
        "authors": [
            {
                "first": "Yayun",
                "last": "Zhang"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Ellis",
                "last": "Cain"
            },
            {
                "first": "Chen",
                "last": "Yu"
            }
        ],
        "year": 2020,
        "journal": "Proceedings of the 42nd Annual Conference of the Cognitive Science Society",
        // "url": "https://psyarxiv.com/aep8f/",
        "pubtype": "proceedings",
        "pdf": "seekingmeaning_0529.pdf"
    },
    "amatuni2017semantic": {
        "title": "Semantic networks generated from early linguistic input",
        "authors": [
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Elika",
                "last": "Bergelson"
            }
        ],
        "year": 2017,
        "journal": "Proceedings of the 39th Annual Conference of the Cognitive Science Society",
        "pages": [
            1538,
            1543
        ],
        "pubtype": "proceedings",
        "pdf": "amatuni2017.pdf",
        "url": "https://www.biorxiv.org/content/10.1101/157701v1",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=5787208810346900138"
    },
    "amatuni2018preserved": {
        "title": "Preserved structure across vector space representations",
        "authors": [
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Estelle",
                "last": "He"
            },
            {
                "first": "Elika",
                "last": "Bergelson"
            }
        ],
        "year": 2018,
        "journal": "Proceedings of the 40th Annual Conference of the Cognitive Science Society",
        "pages": [1298, 1303],
        "pubtype": "proceedings",
        "url": "https://arxiv.org/abs/1802.00840",
        "pdf": "amatuni2018.pdf",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=4820664868228310779"
    },
    "schuller2017interspeech": {
        "title": "The interspeech 2017 computational paralinguistics challenge: Addressee, cold & snoring",
        "authors": [
            {
                "first": "Bjorn",
                "last": "Schuller"
            },
            {
                "first": "Stefan",
                "last": "Steidl"
            },
            {
                "first": "Anton",
                "last": "Batliner"
            },
            {
                "first": "Elika",
                "last": "Bergelson"
            },
            {
                "first": "Jarek",
                "last": "Krajewski"
            },
            {
                "first": "Christoph",
                "last": "Janott"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Marisa",
                "last": "Casillas"
            },
            {
                "first": "Amanda",
                "last": "Seidl"
            },
            {
                "first": "Melanie",
                "last": "Soderstrom"
            },
            {
                "first": "Anne",
                "last": "Warlaumont"
            },
            {
                "first": "Guillermo",
                "last": "Hidalgo"
            },
            {
                "first": "Sebastian",
                "last": "Schnieder"
            },
            {
                "first": "Clemens",
                "last": "Heiser"
            },
            {
                "first": "Winifried",
                "last": "Hohenhorst"
            },
            {
                "first": "Michael",
                "last": "Herzog"
            },
            {
                "first": "Maximilian",
                "last": "Schmitt"
            },
            {
                "first": "Kun",
                "last": "Qian"
            },
            {
                "first": "Yue",
                "last": "Zhang"
            },
            {
                "first": "George",
                "last": "Trigeorgis"
            },
            {
                "first": "Panagiotis",
                "last": "Tzirakis"
            },
            {
                "first": "Stefanos",
                "last": "Zafeiriou"
            },
        ],
        "year": 2017,
        "journal": "Computational Paralinguistics Challenge (ComParE), Interspeech 2017",
        "pages": [3442, 3446],
        "pubtype": "proceedings",
        "pdf": "Schuller_etal_2017.pdf",
        "url": "https://pure.mpg.de/pubman/faces/ViewItemOverviewPage.jsp?itemId=item_2406961",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=10269208872616987681"
    },
    "bergelson2019north": {
        "title": "What do North American babies hear? A large-scale cross-corpus analysis",
        "authors": [
            {
                "first": "Elika",
                "last": "Bergelson"
            },
            {
                "first": "Marisa",
                "last": "Casillas"
            },
            {
                "first": "Melanie",
                "last": "Soderstrom"
            },
            {
                "first": "Amanda",
                "last": "Seidl"
            },
            {
                "first": "Anne",
                "last": "Warlaumont"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            }
        ],
        "year": 2019,
        "journal": "Developmental Science",
        "pages": ["e12724"],
        "pubtype": "journal",
        "publisher": "Wiley Online Library",
        "volume": 22,
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=2154397054005232541",
        "pdf": "Bergelson_et_al-2019_Developmental_Science.pdf"
    },
    "casillas2017babies": {
        "title": "What do Babies hear? Analyses of Child-and Adult-Directed Speech",
        "authors": [
            {
                "first": "Marisa",
                "last": "Casillas"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Amanda",
                "last": "Seidl"
            },
            {
                "first": "Melanie",
                "last": "Soderstrom"
            },
            {
                "first": "Anne",
                "last": "Warlaumont"
            },
            {
                "first": "Elika",
                "last": "Bergelson"
            },
        ],
        "year": 2017,
        "journal": "Interspeech 2017",
        "pages": [2093, 2097],
        "pubtype": "proceedings",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=14566817457634170464",
        "pdf": "Casillas_etal_2017.pdf"
    },
    "bergelson2019day": {
        "title": "Day by day, hour by hour: Naturalistic language input to infants",
        "authors": [
            {
                "first": "Elika",
                "last": "Bergelson"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },
            {
                "first": "Shannon",
                "last": "Dailey"
            },
            {
                "first": "Sharath",
                "last": "Koorathota"
            },
            {
                "first": "Shaelise",
                "last": "Tor"
            },
        ],
        "year": 2019,
        "journal": "Developmental Science",
        "pages": ["e12715"],
        "url": "https://www.biorxiv.org/content/10.1101/157701v1",
        "pubtype": "journal",
        "publisher": "Wiley Online Library",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=18295168751499537799",
        "pdf": "bergelson2018_daybyday.pdf"
    },
    "moore2019point": {
        "title": "Point, walk, talk: Links between three early milestones, from observation and parental report.",
        "authors": [
            {
                "first": "Charlotte",
                "last": "Moore"
            },
            {
                "first": "Shannon",
                "last": "Dailey"
            },
            {
                "first": "Andrei",
                "last": "Amatuni"
            },

            {
                "first": "Hallie",
                "last": "Garrison"
            },
            {
                "first": "Elika",
                "last": "Bergelson"
            },
        ],
        "year": 2019,
        "journal": "Developmental Psychology",
        "pages": [1579, 1593],
        "pubtype": "journal",
        "publisher": "American Psychological Association",
        "volume": "55(8)",
        "pdf": "Moore_et_al_Developmental_Psychology_2019.pdf",
        "url": "https://psyarxiv.com/g6q5u/",
        "scholar": "https://scholar.google.com/scholar?oi=bibs&hl=en&cluster=619727148894029447"
    },
};

export { bib };